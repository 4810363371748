import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAgents } from '../../../api/agents.api'; // Fetch agents from the API
import './needs-approval.component.css';

const NeedsApproval = ({ reports, authToken, organizationID }) => {
  const [reportsThatNeedApproval, setReportsThatNeedApproval] = useState([]);
  const [agents, setAgents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const reportsPerPage = 5;
  const navigate = useNavigate();

  useEffect(() => {
    if (authToken && organizationID) {
      fetchAgents();
    }
  }, [authToken, organizationID]);

  const fetchAgents = async () => {
    try {
      const agentsResponse = await getAgents(organizationID, authToken);
      setAgents(Array.isArray(agentsResponse.agents) ? agentsResponse.agents : []);
    } catch (error) {
      console.error('Error fetching agents:', error);
      setAgents([]);
    }
  };

  // Helper function to get the previous month and year
  const getPreviousMonthAndYear = () => {
    const date = new Date();
    const previousMonth = date.getMonth() === 0 ? 11 : date.getMonth() - 1;
    const year = date.getMonth() === 0 ? date.getFullYear() - 1 : date.getFullYear();
    const monthName = new Date(year, previousMonth).toLocaleString('default', { month: 'long' });
    return { month: monthName, year };
  };

  useEffect(() => {
    if (!reports || reports.length === 0 || agents.length === 0) {
        console.log('❌ Missing reports or agents data');
        return;
    }

    console.log('✅ Reports:', reports);
    console.log('✅ Agents:', agents);

    // ✅ Identify all approved agent reports
    const approvedAgentReports = reports
        .filter(report => report.type === 'agent' && report.approved)
        .map(report => report.agentID);
    console.log('✅ Approved Agent Report IDs:', approvedAgentReports);

    // ✅ Remove fully approved agent reports from the reports array
    const filteredReports = reports.filter(report => 
        !(report.type === 'agent' && report.approved)
    );
    console.log('✅ Reports After Removing Approved Agents:', filteredReports);

    // ✅ Identify unapproved agent reports (partially approved or not approved at all)
    const unapprovedAgentReports = filteredReports.filter(report => 
        report.type === 'agent' &&
        (!report.approved || (report.reportData && report.reportData.some(row => !row.approved)))
    );
    console.log('✅ Unapproved or Partially Approved Agent Reports:', unapprovedAgentReports);

    // ✅ Create a list for agents needing approval
    const agentsNeedingApproval = unapprovedAgentReports.map(report => {
        const matchingAgent = agents.find(agent => agent.agentID === report.agentID);
        return {
            processor: `${matchingAgent?.fName || 'Unknown'} ${matchingAgent?.lName || ''}`,
            agentID: report.agentID,
            type: 'agent',
            approved: false
        };
    });

    // ✅ Identify agents without any reports at all and add placeholders
    const agentsWithoutReports = agents.filter(agent =>
        !reports.some(report => report.type === 'agent' && report.agentID === agent.agentID)
    ).map(agent => ({
        processor: `${agent.fName} ${agent.lName}`,
        agentID: agent.agentID,
        type: 'agent',
        approved: false
    }));

    console.log('✅ Agents Without Reports:', agentsWithoutReports);

    // ✅ Check if all agent reports are approved
    const allAgentReportsApproved = filteredReports
        .filter(report => report.type === 'agent')
        .every(report => report.approved);
    console.log('✅ All Agent Reports Approved:', allAgentReportsApproved);

    const agentSummaryReport = reports.find(report => report.type === 'agent summary');
    const { month, year } = getPreviousMonthAndYear();

    // ✅ Add agent summary if all agents are approved
    if (allAgentReportsApproved && (!agentSummaryReport || !agentSummaryReport.approved)) {
        agentsNeedingApproval.push({
            processor: 'Agent Summary',
            type: 'agent summary',
            approved: false,
            month: `${month} ${year}`,
        });
        console.log('✅ Added Agent Summary Placeholder');
    }

    // ✅ Check if all processor reports are approved
    const allProcessorReportsApproved = reports
        .filter(report => report.type === 'processor')
        .every(report => report.approved);
    console.log('✅ All Processor Reports Approved:', allProcessorReportsApproved);

    const processorSummaryReport = reports.find(report => report.type === 'processor summary');

    if (allProcessorReportsApproved && (!processorSummaryReport || !processorSummaryReport.approved)) {
        agentsNeedingApproval.push({
            processor: 'Processor Summary',
            type: 'processor summary',
            approved: false,
            month: `${month} ${year}`,
        });
        console.log('✅ Added Processor Summary Placeholder');
    }

    // ✅ Bank summary check
    const bankSummaryReport = reports.find(report => report.type === 'bank summary');

    if (allProcessorReportsApproved && (!bankSummaryReport || !bankSummaryReport.approved)) {
        agentsNeedingApproval.push({
            processor: 'Bank Summary',
            type: 'bank summary',
            approved: false,
            month: `${month} ${year}`,
        });
        console.log('✅ Added Bank Summary Placeholder');
    }

    // ✅ Final merge ensuring no approved agents remain
    const combinedReports = [...agentsNeedingApproval, ...agentsWithoutReports];
    console.log('✅ Final Reports That Need Approval:', combinedReports);

    setReportsThatNeedApproval(combinedReports);
}, [reports, agents]);



  // Pagination logic
  const indexOfLastReport = currentPage * reportsPerPage;
  const indexOfFirstReport = indexOfLastReport - reportsPerPage;
  const currentReports = reportsThatNeedApproval.slice(indexOfFirstReport, indexOfLastReport);
  const totalPages = Math.ceil(reportsThatNeedApproval.length / reportsPerPage);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Navigation Logic
  const handleEditClick = (reportID) => navigate(`/report/${reportID}`);
  const handleViewAgentReport = (agentID, month) => navigate(`/agent-report/${agentID}?month=${month}`);
  const handleViewSummaryReport = (month) => navigate(`/agent-summary-report?month=${month}`);
  const handleViewProcessorSummaryReport = (month) => navigate(`/processor-summary-report?month=${month}`);
  const handleViewBankSummaryReport = (month) => navigate(`/bank-summary-report?month=${month}`);

  return (
    <div className="needs-approval-container">
      <h3>Reports Needing Approval</h3>
      {reportsThatNeedApproval.length > 0 ? (
        <>
          <ul className="report-list">
            {currentReports.map((report, index) => (
              // ✅ Skip if processor is 'Unknown Processor'
              (report.processor !== 'Unknown Processor' && (
                <li key={index} className="report-item">
                  <strong>
                    {report.type === 'ar' ? 'Accounts Receivable' :
                      report.type === 'agent summary' ? 'Agent Summary Report' :
                        report.type === 'processor summary' ? 'Processor Summary Report' :
                          report.type === 'bank summary' ? 'Bank Summary Report' :
                            report.processor}
                  </strong>

                  {/* Button Logic Fixed */}
                  {report.type === 'agent summary' ? (
                    <button onClick={() => handleViewSummaryReport(report.month)}>Edit</button>
                  ) : report.type === 'processor summary' ? (
                    <button onClick={() => handleViewProcessorSummaryReport(report.month)}>Edit</button>
                  ) : report.type === 'agent' ? (
                    <button 
                      onClick={() => handleViewAgentReport(
                        report.agentID, 
                        `${report.month || getPreviousMonthAndYear().month} ${getPreviousMonthAndYear().year}`
                      )}
                    >
                      Edit
                    </button>
                  ) : report.type === 'bank summary' ? (
                    <button onClick={() => handleViewBankSummaryReport(report.month ?? getPreviousMonthAndYear().month)}>Edit</button>
                  ) : (
                    <button onClick={() => handleEditClick(report.reportID)}>Edit</button>
                  )}
                </li>
              ))
            ))}
          </ul>

          {/* Pagination */}
          <div className="pagination">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                className={`pagination-btn ${index + 1 === currentPage ? 'active' : ''}`}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </>
      ) : (
        <p>All reports have been approved.</p>
      )}
    </div>
);


};

export default NeedsApproval;
