export const exportToCSV = (data, columns, totals = {}, filename = "export.csv") => {
    console.log("Export Function - Data:", data);
    console.log("Export Function - Columns:", columns);
    console.log("Export Function - Totals:", totals);
  
    if (!data || data.length === 0 || columns.length === 0) {
        alert("No data available to export.");
        return;
    }
  
    // Validate if fields in columns exist in data
    const validColumns = columns.filter((col) => data.some((row) => col.field in row));
    console.log("Valid Columns:", validColumns);
  
    if (validColumns.length === 0) {
        alert("No valid fields to export.");
        return;
    }
  
    // Extract headers
    const headers = validColumns.map((col) => col.label || col.field);
    console.log("Headers:", headers);
  
    // Initialize CSV rows with headers
    const csvRows = [headers.join(",")];
  
    // Add data rows
    data.forEach((row, index) => {
        console.log(`Processing Row ${index}:`, row);
  
        const values = validColumns.map((col) => {
            const value = row[col.field];
            return value !== null && value !== undefined
                ? `"${String(value).replace(/"/g, '""')}"` // Escape quotes
                : ""; // Leave blank for null or undefined
        });
  
        console.log(`Row Values ${index}:`, values);
        csvRows.push(values.join(","));
    });
  
    // Add totals row if provided
    if (Object.keys(totals).length > 0) {
        const totalsRow = validColumns.map((col, index) =>
            index === 0
                ? "Totals" // Add label in the first column
                : totals[col.field] !== undefined
                ? totals[col.field].toFixed(2)
                : ""
        );
        console.log("Totals Row:", totalsRow);
        csvRows.push(totalsRow.join(","));
    }
  
    console.log("Final CSV Rows:", csvRows);
  
    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  